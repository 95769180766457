<div
        #modal="bs-modal"
        [config]="{ backdrop: 'static' }"
        appBsModal
        aria-hidden="true"
        aria-labelledby="modal"
        class="modal fade"
        role="dialog"
        tabindex="-1"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    {{ 'AddFriend' | localize }}
                </h4>
                <button (click)="close()" [attr.aria-label]="l('Close')" class="btn-close" type="button"></button>
            </div>
            <div class="modal-body">
                <div *ngIf="this.interTenantChatAllowed" class="row text-end mb-3">
                    <div class="col">
                        <button (click)="addFromDifferentTenantModal.show()" class="btn btn-primary">
                            {{ 'AddFriendFromDifferentTenant' | localize }}
                        </button>
                    </div>
                </div>

                <friendsLookupTable
                        (itemSelected)="this.addFriendSelected($event)"
                        *ngIf="canListUsersInTenant; else noUserListPermission"
                ></friendsLookupTable>

                <ng-template #noUserListPermission class="mt-5">
                    <form #addFriendForm="ngForm" (ngSubmit)="save()" class="mt-5">
                        <div class="row">
                            <label class="col-4 form-label">
                                <label>{{ 'UserName' | localize }}</label>
                            </label>
                            <label class="col form-label">
                                <input
                                        [(ngModel)]="userName"
                                        class="form-control"
                                        id="UserName"
                                        name="UserName"
                                        required
                                        type="text"
                                />
                            </label>
                        </div>
                    </form>
                    <div class="modal-footer">
                        <button
                                (click)="save()"
                                [disabled]="saving || !addFriendForm.form.valid"
                                class="btn btn-primary"
                                type="button"
                        >
                            <i class="fa fa-save"></i>
                            <span>{{ 'Save' | localize }}</span>
                        </button>
                        <button (click)="close()" class="btn btn-light-primary fw-bold" type="button">
                            {{ 'Cancel' | localize }}
                        </button>
                    </div>
                </ng-template>
            </div>

            <div *ngIf="canListUsersInTenant" class="modal-footer">
                <button (click)="close()" class="btn btn-light-primary fw-bold" type="button">
                    {{ 'Cancel' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>

<addFromDifferentTenantModal #addFromDifferentTenantModal></addFromDifferentTenantModal>
