<div [class]="menuClass" data-kt-menu="true" data-kt-menu-expand="false" id="#kt_app_sidebar_menu">
    <div
            *ngIf="currentTheme.baseSettings.menu.searchActive"
            aria-haspopup="true"
            class="menu-item mb-5 mt-5"
            data-ktmenu-submenu-toggle="hover"
    >
        <menu-search-bar></menu-search-bar>
    </div>
    <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
</div>

<!-- </div> -->

<ng-template #menuListTemplate>
    <ng-container *ngFor="let child of menu.items">
        <ng-container
                *ngIf="showMenuItem(child)"
                [ngTemplateOutletContext]="{ item: child, parentItem: null }"
                [ngTemplateOutlet]="mMenuItem"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <div
            *ngIf="showMenuItem(item)"
            [attr.data-kt-menu-trigger]="item.items.length ? 'click' : null"
            [ngClass]="getItemCssClasses(item, parentItem)"
    >
        <!-- if menu item hasn't submenu -->
        <a
                *ngIf="!item.items.length && !item.external"
                [ngClass]="{ 'without-sub': !item.items.length }"
                [queryParams]="item.parameters"
                [routerLink]="item.route"
                class="menu-link"
                routerLinkActive="active"
        >
            <ng-container
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                    [ngTemplateOutlet]="mMenuItemText"
            ></ng-container>
        </a>
        <a *ngIf="!item.items.length && item.external" [attr.href]="item.route" class="menu-link" target="_blank">
            <ng-container
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                    [ngTemplateOutlet]="mMenuItemText"
            ></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a
                *ngIf="item.items.length"
                [class]="{ active: isMenuItemIsActive(item) }"
                class="menu-link menu-toggle"
                href="javascript:"
        >
            <ng-container
                    [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
                    [ngTemplateOutlet]="mMenuItemText"
            ></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div
                *ngIf="item.items.length"
                [class]="getSubMenuItemCssClass(item, parentItem)"
                [style]="ui.getLeftAsideSubMenuStyles()"
        >
            <div class="menu-subnav">
                <ng-container *ngFor="let child of item.items">
                    <ng-container
                            [ngTemplateOutletContext]="{ item: child, parentItem: item }"
                            [ngTemplateOutlet]="mMenuItem"
                    ></ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <span class="menu-item-here"></span>
    <!-- if menu item has icon -->
    <span *ngIf="item.icon" class="menu-icon">
        <i [ngClass]="item.icon"></i>
    </span>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <span *ngIf="!iconMenu || parentItem != null" class="menu-title">{{ item.name | localize }}</span>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="menu-link-title">
            <span class="menu-link-wrap">
                <span class="menu-text">
                    {{ item.name | localize }}
                </span>
                <span class="menu-link-badge">
                    <span [ngClass]="item.badge.type" class="badge badge-primary">{{ item.badge.value }}</span>
                </span>
            </span>
        </span>
    </ng-template>

    <!-- if menu item has submenu child then put arrow icon -->
    <span *ngIf="item.items.length && (!iconMenu || parentItem != null)" class="menu-arrow"></span>
</ng-template>
<!-- END: Left Aside -->
