<p-autoComplete
        (completeMethod)="searchMenuItem($event)"
        (onSelect)="selectMenuItem($event)"
        *ngIf="isMenuSearchActive"
        [(ngModel)]="selected"
        [minLength]="1"
        [suggestions]="searchMenuResults"
        field="name"
        inputStyleClass="form-control menuSearchBarInput"
        placeholder="{{ 'QuickNav' | localize }}"
        styleClass="menuSearchBarContainer"
></p-autoComplete>
