<div (click)="selectChatFriend.emit($event)" class="d-flex align-items-center chat-user">
    <div class="symbol symbol-45px symbol-circle">
        <friend-profile-picture
                [tenantId]="friend.friendTenantId"
                [userId]="friend.friendUserId"
                class="symbol symbol-45px symbol-circle"
        ></friend-profile-picture>

        <div *ngIf="friend.isOnline"
             class="symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"></div>
        <div *ngIf="!friend.isOnline"
             class="symbol-badge bg-secondary start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"></div>
    </div>
    <div class="ms-5">
        <a class="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2"
           href="javascript:">{{ friend.friendUserName }}</a>
        <div class="fw-bold text-muted">
            <span *ngIf="friend.friendTenancyName" class="text-muted fw-bold fs-sm">
                {{ friend.friendTenancyName }}
            </span>
            <span *ngIf="!friend.friendTenancyName" class="text-muted fw-bold fs-sm">Host</span>
        </div>
    </div>
</div>
<div class="d-flex flex-column align-items-end ms-2">
    <span *ngIf="friend.unreadMessageCount" class="badge badge-circle badge-warning">
        {{ friend.unreadMessageCount }}
    </span>
    <span *ngIf="!friend.unreadMessageCount" class="badge badge-circle badge-warning d-none">
        {{ friend.unreadMessageCount }}
    </span>
</div>