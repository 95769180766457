import { IThemeAssetContributor } from '@app/shared/layout/themes/ThemeAssetContributor';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';
import { DefaultThemeAssetContributor } from '@app/shared/layout/themes/default/DefaultThemeAssetContributor';
import { Theme4ThemeAssetContributor } from '@app/shared/layout/themes/theme4/Theme4ThemeAssetContributor';
import { Theme5ThemeAssetContributor } from '@app/shared/layout/themes/theme5/Theme5ThemeAssetContributor';
import { Theme8ThemeAssetContributor } from '@app/shared/layout/themes/theme8/Theme8ThemeAssetContributor';
import { Theme11ThemeAssetContributor } from '@app/shared/layout/themes/theme11/Theme11ThemeAssetContributor';
export class ThemeAssetContributorFactory {
    static getCurrent(): IThemeAssetContributor {
        let theme = ThemeHelper.getTheme();

        if (theme === 'default') {
            return new DefaultThemeAssetContributor();
        }

        if (theme === 'theme4') {
            return new Theme4ThemeAssetContributor();
        }

        if (theme === 'theme5') {
            return new Theme5ThemeAssetContributor();
        }

        if (theme === 'theme8') {
            return new Theme8ThemeAssetContributor();
        }

        if (theme === 'theme11') {
            return new Theme11ThemeAssetContributor();
        }

        return null;
    }
}
