import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
// import { SharedModuleModule } from '@app/shared-module/shared-module.module';
import {UtilsModule} from '@shared/utils/utils.module';
import {FileUploadModule} from 'primeng/fileupload';
import {SubHeaderComponent} from './sub-header.component';
import {DropdownModule} from 'primeng/dropdown';

@NgModule({
    imports: [CommonModule,
        FileUploadModule,
        DropdownModule,
        UtilsModule,
    ],
    declarations: [SubHeaderComponent],
    exports: [SubHeaderComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],

})
export class SubheaderModule {
}
