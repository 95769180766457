<a (window:resize)="onResize()" [class]="customHrefClass" href="/">
    <img
            *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
            [src]="defaultLogo"
            alt="Logo"
            class="h-35px app-sidebar-logo-default"
    />
    <img
            *ngIf="!appSession.tenant || !appSession.tenant.HasLogo()"
            [src]="defaultSmallLogo"
            alt="Logo"
            class="h-30px app-sidebar-logo-minimize"
    />
    <img
            *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
            [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id
        "
            alt="Logo"
            class="h-35px app-sidebar-logo-default"
    />
    <img
            *ngIf="appSession.tenant && appSession.tenant.HasLogo()"
            [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id
        "
            alt="Logo"
            class="h-30px app-sidebar-logo-minimize"
    />
</a>
