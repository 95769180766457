<!-- BEGIN: Horizontal Menu -->
<div
        [class]="menuClass"
        data-kt-menu="true"
        id="kt_header_menu"
>
    <div *ngIf="isMobileDevice()" aria-haspopup="true" class="menu-item here menu-lg-down-accordion me-lg-1">
        <menu-search-bar></menu-search-bar>
    </div>
    <ng-container *ngFor="let item of menu.items">
        <ng-container
                *ngIf="item.name"
                [ngTemplateOutletContext]="{ item: item, depth: 0 }"
                [ngTemplateOutlet]="kt_menu"
        ></ng-container>
    </ng-container>
    <div *ngIf="!isMobileDevice()" aria-haspopup="true" class="menu-item here menu-lg-down-accordion me-lg-1">
        <menu-search-bar></menu-search-bar>
    </div>
</div>
<!-- END: Horizontal Menu -->

<ng-template #kt_menu let-depth="depth" let-item="item" let-parentItem="parentItem">
    <div
            *ngIf="showMenuItem(item)"
            [attr.data-kt-menu-placement]="item.items.length ? (!parentItem ? 'bottom-start': 'right-start') : null"
            aria-haspopup="true"
            class="{{ getItemCssClasses(item, parentItem, depth) }} me-lg-1"
            data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
    >
        <!-- if item has submenu -->
        <ng-container *ngIf="item.items.length">
            <span class="{{ getAnchorItemCssClasses(item, parentItem) }}">
                <ng-container
                        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: depth + 1 }"
                        [ngTemplateOutlet]="kt_menu_item_inner"
                ></ng-container>

                <i *ngIf="item.items.length && !parentItem" class="la la-angle-down ms-2"></i>
                <i *ngIf="item.items.length && parentItem" class="la la-angle-right"></i>
            </span>
        </ng-container>

        <!-- if item hasn't sumbenu -->
        <ng-container *ngIf="!item.items.length">
            <a
                    *ngIf="!item.external"
                    [ngClass]="{'active': isMenuItemIsActive(item)}"
                    [queryParams]="item.parameters"
                    [routerLink]="item.route"
                    class="{{ getAnchorItemCssClasses(item, parentItem) }}"
            >
                <ng-container
                        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: depth + 1 }"
                        [ngTemplateOutlet]="kt_menu_item_inner"
                ></ng-container>
            </a>
            <a
                    *ngIf="item.external"
                    [attr.href]="[item.route]"
                    class="{{ getAnchorItemCssClasses(item, parentItem) }}"
            >
                <ng-container
                        [ngTemplateOutletContext]="{ item: item, parentItem: parentItem, depth: depth + 1 }"
                        [ngTemplateOutlet]="kt_menu_item_inner"
                ></ng-container>
            </a>
        </ng-container>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <ng-container *ngIf="item.items.length">
            <div class="{{ getSubmenuCssClasses(item, parentItem, depth) }}" data-kt-menu-dismiss="true">
                <span [ngClass]="{ 'menu-arrow-adjust': !parentItem }" class="menu-arrow"></span>
                <div *ngIf="item.items.length" class="menu-subnav">
                    <ng-container *ngFor="let child of item.items">
                        <ng-container
                                [ngTemplateOutletContext]="{ item: child, parentItem: item, depth: depth + 1 }"
                                [ngTemplateOutlet]="kt_menu"
                        ></ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<!-- item inner -->
<ng-template #kt_menu_item_inner let-item="item" let-parentItem="parentItem">
    <!-- if menu item has icon -->
    <i *ngIf="item.icon" [ngClass]="item.icon" class="menu-icon"></i>

    <ng-container>
        <!-- menu item title text -->
        <span class="menu-title">{{ item.name | localize }}</span>
    </ng-container>
</ng-template>
