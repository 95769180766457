<div
        *ngIf="(isHost || ('App.ChatFeature' | checkFeature)) && !chatConnected"
        class="d-flex align-items-center ms-1 ms-lg-3"
        id="chat_is_connecting_icon"
>
    <button [class]="customStyle">
        <img
                *ngIf="!chatConnected"
                placement="left"
                src="./assets/common/images/loading.gif"
                style="width: 23px"
                tooltip="{{ 'ChatIsConnecting' | localize }}"
        />
    </button>
</div>
<div
        *ngIf="(isHost || ('App.ChatFeature' | checkFeature)) && chatConnected"
        class="d-flex align-items-center ms-1 ms-lg-3"
        id="kt_drawer_chat_toggle"
>
    <button [class]="customStyle" class="unread-notification" id="chatIconUnRead">
        <i [class]="iconStyle"></i>
        <span
                *ngIf="unreadChatMessageCount"
                class="
                position-absolute
                top-0
                start-100
                translate-middle
                badge badge-circle badge-warning
                unread-chat-message-count
            "
        >
            {{ unreadChatMessageCount }}
        </span>
    </button>
</div>
